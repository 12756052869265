import type { FC } from 'react';
import React, { useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Button, Divider, Drawer, Hidden, Typography, useMediaQuery } from '@mui/material';

import useAuth from 'hooks/useAuth';
import GroupWorkIcon from '@mui/icons-material/GroupWorkOutlined';
import ListIcon from '@mui/icons-material/ListAltOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutline';
import AnalyticsIcon from '@mui/icons-material/AnalyticsOutlined';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import NavSection, { getItems, NavItemModel } from '../NavSection';
import Scrollbar from '../Scrollbar';
import { localRoutes, remoteRoutes, rootAdminRoles, rootUserRoles, systemPermissions } from '../../constants';
import { hasValue } from '../inputs/inputHelpers';
import { appSettings, remoteConfigData } from '../../config';
import WalletIcon from '@mui/icons-material/Wallet';
import HomeIcon from '@mui/icons-material/Home';
import MoneyInIcon from 'icons/MoneyIn';
import MoneyOutIcon from 'icons/MoneyOut';
import PayLogo from '../../logos/PayLogo';
import { toTitleCase } from '../../utils/stringHelpers';
import { useTheme } from '@mui/material/styles';
import ContactUsButton from './ContactUsButton';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

type Section = {
  title: string,
  items: NavItemModel[]
};

const DashboardSidebar: FC<React.PropsWithChildren<DashboardSidebarProps>> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const sections:Section[] = [
    isLargeScreen ? {
      title: 'Analytics',
      items: [
        {
          title: 'Dashboard',
          path: '/dashboard',
          icon: <DashboardIcon fontSize="small" />
        },
        {
          title: 'Reports',
          path: '/reports',
          icon: <AnalyticsIcon fontSize="small" />,
          roles: rootUserRoles
        },
      ]
    } : {
      title: 'Home',
      items: [
        {
          title: 'Home',
          path: '/home',
          icon: <HomeIcon fontSize="small" />
        }
      ]
    },
    {
      title: 'Payments',
      items: [
        {
          title: 'Transactions',
          icon: <ListIcon fontSize="small" />,
          path: localRoutes.transactions,
          roles: [...rootAdminRoles, systemPermissions.COLLECTION_TRIGGER, systemPermissions.COLLECTION_VIEW, systemPermissions.DISBURSEMENT_TRIGGER, systemPermissions.DISBURSEMENT_VIEW]
        },
        {
          title: 'Collections',
          path: localRoutes.collections,
          icon: <MoneyInIcon />,
          roles: [...rootAdminRoles, systemPermissions.COLLECTION_TRIGGER, systemPermissions.COLLECTION_VIEW]
        },
        {
          title: 'Disbursements',
          icon: <MoneyOutIcon />,
          path: localRoutes.disbursements,
          roles: [...rootAdminRoles, systemPermissions.BULK_DISBURSEMENT_INITIATE, systemPermissions.DISBURSEMENT_TRIGGER, systemPermissions.DISBURSEMENT_VIEW]
        },
        {
          title: 'Bulk Disbursements',
          icon: <MoneyOutIcon />,
          path: localRoutes.bulkDisbursements,
          roles: [...rootAdminRoles, systemPermissions.BULK_DISBURSEMENT_INITIATE, systemPermissions.BULK_DISBURSEMENT_PAY, systemPermissions.BULK_DISBURSEMENT_APPROVE]
        },
        {
          title: 'Top Ups',
          path: localRoutes.topups,
          icon: <MoneyInIcon />,
          roles: [...rootAdminRoles, systemPermissions.PARTNER_VIEW_TOPUPS]
        },
        {
          title: 'Liquidations',
          path: localRoutes.liquidations,
          icon: <MoneyOutIcon />,
          roles: [...rootAdminRoles, systemPermissions.PARTNER_VIEW_LIQUIDATIONS]
        },
        {
          title: 'Beneficiaries',
          path: localRoutes.beneficiaries,
          icon: <PeopleIcon fontSize="small" />,
          roles: [...rootAdminRoles, systemPermissions.BULK_DISBURSEMENT_INITIATE, systemPermissions.DISBURSEMENT_TRIGGER]
        }
      ]
    },
    {
      title: 'Management',
      items: [
        {
          title: 'Staff',
          path: '/admin/staff',
          icon: <PeopleIcon fontSize="small" />,
          roles: [...rootAdminRoles, systemPermissions.PARTNERS_VIEW, systemPermissions.PARTNERS_EDIT, systemPermissions.PARTNER_MEMBERS_EDIT, systemPermissions.PARTNER_MEMBERS_VIEW]
        },
        {
          title: 'Wallets',
          path: '/admin/wallets',
          icon: <WalletIcon fontSize="small" />,
          roles: [...rootAdminRoles, systemPermissions.WALLET_TOPUP, systemPermissions.WALLET_VIEW, systemPermissions.WALLET_UPDATE, systemPermissions.WALLET_CREATE ]
        },
        {
          title: 'Partners',
          path: '/admin/partners',
          icon: <GroupWorkIcon fontSize="small" />,
          roles: [...rootAdminRoles, systemPermissions.PARTNERS_VIEW, systemPermissions.PARTNERS_EDIT]
        },
        {
          title: 'Settings',
          path: '/admin/settings',
          icon: <SettingsIcon fontSize="small" />,
          roles: [...rootAdminRoles, systemPermissions.VENDOR_BALANCES_VIEW],
          children: [
            {
              title: 'Vendor Balances',
              path: '/admin/vendor-balances',
            },
            {
              title: 'Stanbic Mini Statement',
              path: '/admin/stanbic-mini-statement',
            },
            {
              title: 'Banks',
              path: '/admin/banks',
            },
          ]
        }
      ]
    }
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const sectionsData = useMemo<Section[]>(() => sections
    .map((it) => ({ ...it, items: getItems(it.items, user) }))
    .filter((it) => hasValue(it.items)), [user, sections]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Hidden lgDown>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                p: 2
              }}
            >
              <RouterLink to="/">
                <PayLogo
                    sx={{
                      width: 50,
                      height: 50,
                    }}
                />
              </RouterLink>
              <Box
                  sx={{
                    ml: 1
                  }}
              >
                <Typography
                    color="text.primary"
                    variant="h6"
                >
                  {appSettings.appName}
                </Typography>
              </Box>
            </Box>
          </Hidden>
          <Box sx={{ px: 2, pb: 2 }}>
            <Box
                sx={{
                  alignItems: 'center',
                  backgroundColor: 'background.default',
                  borderRadius: 1,
                  display: 'flex',
                  overflow: 'auto',
                  p: 2
                }}
            >
              <Box>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {toTitleCase(user.name)}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  {user.email}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            {sectionsData.map((section) => (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 3
                  }
                }}
                {...section}
              />
            ))}
          </Box>
          <Box sx={{
            marginTop: 'auto',
            width: '100%'
          }}
          >
            <Divider />
            <Box
              sx={{
                px: 2,
                py: 3
              }}
            >
              <Button
                  color="primary"
                  fullWidth
                  sx={{
                    mt: 2,
                    justifyContent: 'flex-start',
                    textTransform: 'none',
                  }}
                  href={remoteConfigData.feedbackSurveyUrl()}
                  target="_blank"
                  variant="text"
              >
                Give us feedback
              </Button>
              <ContactUsButton title="Contact us"/>
              <Button
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                href={remoteRoutes.apiDocumentation}
                target="_blank"
                variant="contained"
              >
                API Documentation
              </Button>
            </Box>
          </Box>
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: '100%',
              width: 280,
              border: 'none'
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default DashboardSidebar;
