import type { FC, ReactNode } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { ComboModel } from '../__generated__/paymentTypes';
import { WalletComboModel } from '../__generated__/apiTypes';
import { remoteRoutes } from '../constants';
import { getDefaultWallet } from '../hooks/useDefaultWallet';
import Loading from '../components/loaders/Loading';
import { Alert, Box } from '@mui/material';
import { get } from '../utils/ajax';

type SelectedWalletProps = {
  wallet: WalletComboModel | null
  loading: boolean
  saveSelectedWallet: (wallet: WalletComboModel | null) => void
};

interface SelectedWalletProviderProps {
  children?: ReactNode;
  isAuthenticated: boolean
}

const SelectedWalletContext = createContext<SelectedWalletProps>({
  wallet: null,
  loading: true,
  saveSelectedWallet: () => {
  }
});

export const SelectedWalletProvider: FC<React.PropsWithChildren<SelectedWalletProviderProps>> = (props) => {
  const { children, isAuthenticated } = props;
  const [wallet, setWallet] = useState<ComboModel | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!isAuthenticated) return;
    setLoading(true);
    get(remoteRoutes.walletsCombo, data=>{
      const defaultWallet = getDefaultWallet(data);
      setWallet(defaultWallet);
      setLoading(false);
    }, undefined, ()=>{
      setLoading(false);
    });
  }, [isAuthenticated]);

  const saveSelectedWallet = (walletToSave: ComboModel | null): void => {
    setWallet(walletToSave);
  };

  if (!isAuthenticated)
    return <>{children}</>;

  return (
        <SelectedWalletContext.Provider
            value={{
              wallet,
              loading,
              saveSelectedWallet
            }}
        >
            {loading ? <Loading/> : (
              wallet ? children :
                    <Box p={5}
                         display='flex'
                         justifyContent='center'
                    >
                        <Alert severity='error'>We were unable to load your wallet details</Alert>
                    </Box>

            )}
        </SelectedWalletContext.Provider>
  );
};

export const useSelectedWalletContext = () => {
  return useContext(SelectedWalletContext);
};


export default SelectedWalletContext;
